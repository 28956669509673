import {Api} from "../libs/Api";
import {ITenant, Role, UserInfo} from "./types";

export class CurrentUserProvider {

    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async profile(): Promise<UserInfo> {
        return this.api.get<UserInfo>("/api/v1/current-user");
    }

    async tenants(): Promise<ITenant[]> {
        return this.api.get<ITenant[]>("/api/v1/current-user/tenants");
    }

    async tenant(tenantUuid: string): Promise<ITenant> {
        return this.api.get<ITenant>(`/api/v1/current-user/tenants/${tenantUuid}`);
    }

    async role(tenantUuid: string): Promise<Role> {
        return this.api.get<Role>(`/api/v1/current-user/tenants/${tenantUuid}/role`);
    }

}