import {Container, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useEffect} from "react";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {gs} from "../../theme";
import {useNavigate} from "react-router-dom";

interface AuthLogoutProps {}

export function AuthLogout(props: AuthLogoutProps) {
    const {logout} = useAuthContext();
    const {t} = useLanguageContext();
    const navigate = useNavigate();
    useEffect(() => {
        logout();
        setTimeout(() => {
            navigate("/");
        }, 1000);
    }, [logout, navigate]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("logout.title")}
                        crumbs={[{label: t("login.singular"), href: "/auth/login"}]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">{t("logout.processing")}</Typography>
                </Grid>
            </Grid>
        </Container>
    );
}
