import {red} from "@mui/material/colors";
import {createTheme} from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: "rgb(226, 0, 42)"
        },
        secondary: {
            main: "#464646"
        },
        error: {
            main: red.A400
        },
        background: {
            default: "#555555"
        }
    },
    typography: {
        fontSize: 15,
        fontFamily: "FrutigerNextLight, Arial, Helvetica, sans-serif",
        h1: {
            fontSize: "2.5rem",
            fontWeight: 700,
            color: "rgb(226, 0, 42)"
        },
        h2: {
            fontSize: "2.2rem",
            color: "rgb(102, 102, 102)"
        },
        h3: {
            fontSize: "1.7rem",
            color: "rgb(102, 102, 102)"
        },
        h4: {
            fontSize: "1.5rem",
            color: "rgb(102, 102, 102)"
        },
        h5: {
            fontSize: "1.3rem",
            color: "rgb(102, 102, 102)"
        },
        h6: {
            fontSize: "1.2rem",
            color: "rgb(102, 102, 102)"
        }
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgb(245, 245, 245)"
                }
            }
        }
    }
});

export const gs = 3;

export default theme;
