import * as React from "react";
import {Grid, Typography} from "@mui/material";
import {Address} from "../../domain/types";

interface AddressDisplayProps {
    address: Address;
}

export function AddressDisplay(props: AddressDisplayProps) {
    const {address} = props;

    return (
        <Grid container spacing={1}>
            {address.line1 &&
                <Grid item>
                    <Typography variant="body1">{address.line1}</Typography>
                </Grid>
            }
            {address.line2 &&
                <Grid item>
                    <Typography variant="body1">{address.line2}</Typography>
                </Grid>
            }
            {(address.zip || address.city) &&
                <Grid item>
                    <Typography variant="body1">{[address.zip, address.city].filter((element) => Boolean(element)).join(" ")}</Typography>
                </Grid>
            }
            {address.countryCode &&
                <Grid item>
                    <Typography variant="body1">{address.countryCode}</Typography>
                </Grid>
            }
        </Grid>
    );
}
