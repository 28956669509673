import {Api} from "../libs/Api";
import {Page, PageChange, TenantUserInfo, UserInfo} from "./types";

export class UsersProvider {

    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async getUserInfo(username: string): Promise<UserInfo> {
        return this.api.get<UserInfo>(`/api/v1/users/${username}`);
    }

    async listTenantUsers(tenantUuid: string, pageChange: PageChange): Promise<Page<TenantUserInfo>> {
        const url = this.api.appendPagingParams(`/api/v1/tenants/${tenantUuid}/users`, pageChange);
        return this.api.get<Page<TenantUserInfo>>(url);
    }

    async getTenantUser(tenantUuid: string, username: string): Promise<TenantUserInfo> {
        return this.api.get<TenantUserInfo>(`/api/v1/tenants/${tenantUuid}/users/${username}`);
    }

}