import React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

interface AConfirmDialogProps {
    open: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
}

export function AConfirmDialog(props: AConfirmDialogProps) {
    const {open, title, message, onConfirm, onCancel} = props;
    const {t} = useLanguageContext();
    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant="outlined">{t("cancel")}</Button>
                <Button onClick={onConfirm} variant="contained" color="primary">{t("confirm")}</Button>
            </DialogActions>
        </Dialog>
    );
}