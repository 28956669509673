import React, {useMemo} from "react";
import {Avatar} from "@mui/material";
import {InvoiceStateIcon} from "./InvoiceStateIcon";
import {InvoiceState} from "../../domain/types";
import {amber, green} from "@mui/material/colors";

interface InvoiceStateAvatarProps {
    invoiceState: InvoiceState;
}

export function InvoiceStateAvatar(props: InvoiceStateAvatarProps) {
    const {invoiceState} = props;

    const invoiceStateColor = useMemo(() => {
        switch (invoiceState) {
            case InvoiceState.Sent:
                return amber[700];
            case InvoiceState.PaymentProcessing:
                return amber[300];
            case InvoiceState.Payed:
                return green[300];
            default:
                return green[300];
        }
    }, [invoiceState]);

    return (
        <Avatar sx={{backgroundColor: invoiceStateColor}}>
            {InvoiceStateIcon(invoiceState)}
        </Avatar>
    );
}