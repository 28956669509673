import React, {PropsWithChildren} from "react";

interface ATabPanelProps extends PropsWithChildren<{
    value: number;
    index: number;
}> {}

export function ATabPanel(props: ATabPanelProps) {
    const {children, value, index} = props;

    if (value != index) {
        return null;
    }

    return (
        <>{children}</>
    );
}