import {DateTime} from "luxon";
import {MouseEvent} from "react";

export function appBaseUrl(): string {
    const formattedPort = window.location.port ? `:${window.location.port}` : "";
    return `${window.location.protocol}//${window.location.hostname}${formattedPort}`;
}

export function randomBlue(): number[] {
    return [Math.floor(Math.random() * 150), Math.floor(Math.random() * 150), 200];
}

export function luxonDate(date?: any): DateTime {
    if (!date) {
        return DateTime.local();
    } else if (typeof date === "string") {
        return DateTime.fromISO(date);
    } else if (date instanceof DateTime) {
        return date;
    } else if (date instanceof Date) {
        return DateTime.fromJSDate(date);
    }
    throw Error("Date was in a not supported type");
}

export function stopMouseEvent(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
}

export enum DateDisplayFormat {
    Timestamp = "Timestamp",
    Age = "Age",
    Date = "Date",
    Time = "Time"
}
