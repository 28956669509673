import {Container, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useEffect} from "react";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {AuthProvider} from "../../domain/AuthProvider";
import {Api} from "../../libs/Api";
import {AuthFlow} from "../../libs/auth";
import {gs} from "../../theme";

interface AuthLoginProps {}

export function AuthLogin(props: AuthLoginProps) {
    const {t} = useLanguageContext();
    useEffect(() => {
        const authProvider = new AuthProvider(new Api("/auth/login"));
        authProvider.configuration().then((configuration) => {
            const authFlow = new AuthFlow(configuration);
            window.location.href = authFlow.idpLoginUrl;
        });
    }, []);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("login.title")}
                        crumbs={[{label: t("login.singular"), href: "/auth/login"}]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">{t("login.loading")}</Typography>
                </Grid>
            </Grid>
        </Container>
    );
}
