import {PageChange} from "../domain/types";
import {AuthenticatedToken} from "../domain/auth";

const baseHeaders = {
    'Content-Type': 'application/json',
};

function baseUrlFromHostname(): string {
    const hostname = window.location.hostname;
    switch (hostname) {
        case "test.demanda.at":
            return "https://api-test.demanda.at";
        case "app.demanda.at":
        default:
            return "https://api.demanda.at";
    }
}

export class Api {

    readonly auth?: AuthenticatedToken;
    readonly notAuthenticatedUrl: string;
    readonly apiBaseUrl: string;

    constructor(notAuthenticatedUrl: string, auth?: AuthenticatedToken) {
        this.notAuthenticatedUrl = notAuthenticatedUrl;
        this.auth = auth;
        this.apiBaseUrl = process.env.REACT_APP_API_ENDPOINT || baseUrlFromHostname();
    }

    private buildUrl(path: string) {
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        return this.apiBaseUrl + path;
    }

    private buildHeaders() {
        if (this.auth && this.auth.isLoggedIn) {
            return {
                'Authorization': this.auth.authHeader,
                ...baseHeaders
            }
        }
        return baseHeaders;
    }

    private async handleResponse(response: Response) {
        if(response.status == 401) {
            console.error(`Not authenticated. Redirecting to ${this.notAuthenticatedUrl}`);
            this.logAuth();
            window.location.href = this.notAuthenticatedUrl;
        }
        if(response.status == 200 || response.status == 201) {
            try {
                return await response.json();
            } catch (error) {
                console.warn('Body is empty. Return nothing.');
                return '';
            }
        }
        const error = await response.json();
        throw new Error(error.message);
    }

    async fetch<RT>(path: string, method?: string, body?: any): Promise<RT> {
        const url = this.buildUrl(path);
        const response = await fetch(url, {
            method: method || 'GET',
            body: body ? JSON.stringify(body) : null,
            headers: new Headers(this.buildHeaders())
        });
        return await this.handleResponse(response) as RT;

    }

    toPagingParams(page: PageChange): string {
        return `page=${page.page}&size=${page.pageSize}`;
    }

    appendPagingParams(url: string, page: PageChange): string {
        return url + (url.includes('?') ? '&' : '?') + this.toPagingParams(page);
    }

    async get<RT>(path: string): Promise<RT> {
        return this.fetch<RT>(path, 'GET');
    }

    async post<RT>(path: string, body: any): Promise<RT> {
        return this.fetch<RT>(path, 'POST', body);
    }

    async put<RT>(path: string, body: any): Promise<RT> {
        return this.fetch<RT>(path, 'PUT', body);
    }

    async delete<RT>(path: string, body?: any): Promise<RT> {
        return this.fetch<RT>(path, 'DELETE', body);
    }

    private logAuth() {
        if (!this.auth) {
            console.log("No authentication set");
        } else {
            console.log("Is logged in: " + this.auth.isLoggedIn);
            console.log("Auth header: " + this.auth.authHeader);
        }
    }
}