import * as React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useNavigate, useParams} from "react-router-dom";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {Role} from "../../domain/types";
import {useEffect, useState} from "react";
import {RolesProvider} from "../../domain/RolesProvider";
import {Button, Container, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {gs} from "../../theme";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {LabeledItem} from "../../base/data/LabeledItem";
import {AHeader} from "../../base/layout/AHeader";

interface RoleViewProps {
}

export function RoleView(props: RoleViewProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const tenantContext = useTenantContext();
    const {tenantUuid} = tenantContext;
    const navigate = useNavigate();
    const {t} = useLanguageContext();

    const [role, setRole] = useState<Role>();

    useEffect(() => {
        if (routeParams.roleUuid && api.auth) {
            const rolesProvider = new RolesProvider(api);
            rolesProvider.get(tenantUuid, routeParams.roleUuid).then(setRole);
        }
    }, [api, tenantUuid, routeParams]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={role ? role.name : "..."}
                        crumbs={[
                            {label: t("roles.plural"), href: `/tenants/${tenantUuid}/roles`},
                            {label: role ? role.name : "...", href: `/tenants/${tenantUuid}/roles/${routeParams.roleUuid}`}
                        ]} />
                </Grid>
                {role &&
                    <>
                        <Grid item xs={12}>
                            <BoxedPaper>
                                <Grid container spacing={gs}>
                                    <LabeledItem label={t("roles.singular")}>
                                        {role.name}
                                    </LabeledItem>
                                    <LabeledItem label={t("roles.description")}>
                                        {role.description}
                                    </LabeledItem>
                                </Grid>
                            </BoxedPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <AHeader title={t("roles.permissions")} variant="h2">
                                {tenantContext.role && tenantContext.role.canExertPermission(`/tenants/${tenantUuid}/roles/${routeParams.roleUuid}`, "WRITE") &&
                                    <Button variant="outlined">{t("roles.addPermission.title")}</Button>
                                }
                            </AHeader>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Table stickyHeader size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="50%">{t("roles.entity")}</TableCell>
                                            <TableCell>{t("roles.permissions")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {role.permissions.permissions.map((p) => (
                                            <TableRow key={p.entity}>
                                                <TableCell>{p.entity}</TableCell>
                                                <TableCell>{p.permissions.join(", ")}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </>
                }
            </Grid>
        </Container>
    );
}
