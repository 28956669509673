import React from "react";
import {InvoiceState} from "../../domain/types";
import {CreditScore, Outbox, Payment, QuestionMark} from "@mui/icons-material";

export function InvoiceStateIcon(invoiceState: InvoiceState) {
    switch (invoiceState) {
        case InvoiceState.Sent:
            return <Outbox />;
        case InvoiceState.PaymentProcessing:
            return <Payment />;
        case InvoiceState.Payed:
            return <CreditScore />;
        default:
            return <QuestionMark />;
    }
}