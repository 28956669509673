import * as React from "react";
import {Avatar, Button, Divider, Menu, MenuItem} from "@mui/material";
import {useCallback, useState} from "react";
import {useLanguageContext} from "../context/language/LanguageContext";
import {useNavigate} from "react-router-dom";

interface UserMenuProps {
}

export function UserMenu(props: UserMenuProps) {
    const {t} = useLanguageContext();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleShowMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleCloseMenu = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleUserProfile = useCallback(() => {
        handleCloseMenu();
        navigate("/current-user/profile");
    }, [handleCloseMenu, navigate]);

    const handleLogout = useCallback(() => {
        handleCloseMenu();
        navigate("/auth/logout");
    }, [handleCloseMenu, navigate]);

    return (
        <>
            <Button onClick={handleShowMenu}>
                <Avatar sx={{ml: 2}}>
                    MS
                </Avatar>
            </Button>
            <Menu open={Boolean(anchorEl)} onClose={handleCloseMenu} anchorEl={anchorEl} keepMounted
                  anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                  transformOrigin={{vertical: "top", horizontal: "center"}}>
                <UserMenuItem label={t("userMenu.profile")} onClick={handleUserProfile}/>
                <Divider />
                <UserMenuItem label={t("userMenu.logout")} onClick={handleLogout}/>
            </Menu>
        </>
    );
}

interface UserMenuItemProps {
    label: string;
    onClick: () => void;
}

function UserMenuItem(props: UserMenuItemProps) {
    const {label, onClick} = props;

    return (
        <MenuItem onClick={onClick}>{label}</MenuItem>
    );

}