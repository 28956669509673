import {Api} from "../libs/Api";
import {AuthCodeResponse, AuthConfiguration} from "./auth";
import {AuthFlow} from "../libs/auth";

export class AuthProvider {

    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async configuration(): Promise<AuthConfiguration> {
        return this.api.get<AuthConfiguration>('/api/v1/auth/configuration');
    }

    async processToken(code: string): Promise<AuthCodeResponse> {
        const configuration = await this.configuration();
        const authFlow = new AuthFlow(configuration);
        const request = {clientId: configuration.clientId, code, redirectUri: authFlow.redirectUri};

        const response = await this.api.post<AuthCodeResponse>('/api/v1/auth', request);
        return response;
    }

}