import {Link} from "@mui/material";
import * as React from "react";
import {PropsWithChildren, useCallback} from "react";
import {useNavigate} from "react-router-dom";

interface ALinkProps
    extends PropsWithChildren<{
        href?: string;
        onClick?: () => void;
    }> {}

export function ALink(props: ALinkProps) {
    const {children, href, onClick} = props;
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        if (onClick) {
            onClick();
        }
        if (href) {
            navigate(href);
        }
    }, [href, onClick, navigate]);

    return (
        <Link color="secondary" onClick={handleClick} sx={{cursor: "pointer"}}>
            {children}
        </Link>
    );
}
