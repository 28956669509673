import {Permission, Role} from "./types";

export class RoleWrapper {

    readonly role: Role;

    constructor(role: Role) {
        this.role = role;
    }

    private static parseEntity(entity: string): string[] {
        if (!entity || entity.trim() === "") {
            throw new Error("Entity cannot be null or blank");
        }
        if (!entity.startsWith("/")) {
            throw new Error("Entity must start with a slash");
        }
        return entity.substring(1).split("/");
    }

    private static permissionMatchesEntity(permission: Permission, entity: string): boolean {
        const parsedPermissionEntity = RoleWrapper.parseEntity(permission.entity);
        const parsedEntity = RoleWrapper.parseEntity(entity);

        for (let i = 0; i < parsedPermissionEntity.length; i++) {
            if (parsedPermissionEntity[i] === "*") {
                continue;
            }
            if (parsedPermissionEntity[i] === "**") {
                return true;
            }
            if (i >= parsedEntity.length) {
                return false;
            }
            if (parsedPermissionEntity[i] !== parsedEntity[i]) {
                return false;
            }
        }
        return permission.entity === entity;
    }

    private static permissionHasPermission(permission: Permission, requestedPermission: string): boolean {
        return permission.permissions.includes(requestedPermission);
    }

    private static permissionCanExertPermission(permission: Permission, requestedEntity: string, requestedPermission: string): boolean {
        return this.permissionMatchesEntity(permission, requestedEntity) && this.permissionHasPermission(permission, requestedPermission);
    }

    canExertPermission(entity: string, permission: string): boolean {
        for (const p of this.role.permissions.permissions) {
            if (RoleWrapper.permissionCanExertPermission(p, entity, permission)) {
                return true;
            }
        }
        return false;
    }

}