import * as React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {Container, Grid} from "@mui/material";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {gs} from "../../theme";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {useNavigate, useParams} from "react-router-dom";
import {TenantUserInfo} from "../../domain/types";
import {useEffect, useMemo, useState} from "react";
import {UsersProvider} from "../../domain/UsersProvider";
import {UserInfoWrapper} from "../../domain/users";
import {BoxedPaper} from "../../base/layout/BoxedPaper";
import {LabeledItem} from "../../base/data/LabeledItem";
import {RoleDisplay} from "../../base/displays/RoleDisplay";
import {AContainer} from "../../base/layout/AContainer";

interface UserViewProps {
}

export function UserView(props: UserViewProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();

    const [tenantUser, setTenantUser] = useState<TenantUserInfo>();

    useEffect(() => {
        if (routeParams.username && api.auth) {
            const usersProvider = new UsersProvider(api);
            usersProvider.getTenantUser(tenantUuid, routeParams.username).then(setTenantUser);
        }
    }, [api, tenantUuid, routeParams]);

    const userInfoWrapper = useMemo<UserInfoWrapper | undefined>(() => {
        if (tenantUser) {
            return new UserInfoWrapper(tenantUser.user);
        }
        return undefined;
    }, [tenantUser]);

    return (
        <AContainer>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={userInfoWrapper ? userInfoWrapper.displayName : "..."}
                        crumbs={[
                            {label: t("users.plural"), href: `/tenants/${tenantUuid}/users`},
                            {label: userInfoWrapper ? userInfoWrapper.displayName : "...", href: `/tenants/${tenantUuid}/users/${routeParams.username}`}
                        ]} />
                </Grid>
                {tenantUser &&
                    <Grid item xs={12}>
                        <BoxedPaper>
                            <Grid container spacing={gs}>
                                <LabeledItem label={t("users.givenName")}>
                                    {tenantUser.user.givenName}
                                </LabeledItem>
                                <LabeledItem label={t("users.name")}>
                                    {tenantUser.user.name}
                                </LabeledItem>
                                <LabeledItem label={t("users.email")}>
                                    {tenantUser.user.email}
                                </LabeledItem>
                                <LabeledItem label={t("roles.singular")}>
                                    <RoleDisplay role={tenantUser.tenantUser.role} />
                                </LabeledItem>
                            </Grid>
                        </BoxedPaper>
                    </Grid>
                }
            </Grid>
        </AContainer>
    );
}
