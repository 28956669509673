import React, {useEffect, useState} from "react";
import {IInvoice, IInvoiceDocument} from "../../../domain/types";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {Grid} from "@mui/material";
import {gs} from "../../../theme";
import {useAuthContext} from "../../../context/auth/AuthContext";
import {useTenantContext} from "../../../context/tenant/TenantContext";
import {InvoicesProvider} from "../../../domain/InvoicesProvider";

interface InvoiceDocumentsProps {
    invoice: IInvoice;
}

export function InvoiceDocuments(props: InvoiceDocumentsProps) {
    const {invoice} = props;
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const {t} = useLanguageContext();

    const [invoiceDocuments, setInvoiceDocuments] = useState<IInvoiceDocument[]>();

    useEffect(() => {
        if (api.auth) {
            const invoicesProvider = new InvoicesProvider(api);
            invoicesProvider.listDocuments(tenantUuid, invoice.uuid).then(setInvoiceDocuments);
        }
    }, [api, tenantUuid, invoice]);

    if (!invoiceDocuments) {
        return null; // TODO too simple
    }

    return (
        <Grid container spacing={gs}>
            {invoiceDocuments.map((document) => (
                <Grid key={document.uuid} item xs={12}>
                    {document.filename}
                </Grid>
            ))}
        </Grid>
    );
}