import {TextField} from "@mui/material";
import * as React from "react";
import {useEffect, useRef} from "react";

interface TextInputProps {
    label: string;
    value: string;
    onChange: (value: string) => void;
    rows?: number;
    disabled?: boolean;
    focused?: boolean;
}

export function TextInput(props: TextInputProps) {
    const {label, value, onChange, rows, disabled, focused} = props;

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (focused && inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [focused, inputRef]);

    return (
        <TextField
            variant="outlined"
            label={label}
            value={value}
            fullWidth
            rows={rows}
            multiline={Boolean(rows)}
            disabled={disabled}
            onChange={(e) => onChange(e.target.value)}
            inputRef={inputRef}
        />
    );
}
