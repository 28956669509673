import * as React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import Container from "@mui/material/Container";
import {gs} from "../../theme";
import {Button, Card, CardActions, CardContent, CardHeader, Grid} from "@mui/material";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useEffect, useState} from "react";
import {CurrentUserProvider} from "../../domain/CurrentUserProvider";
import {ITenant, Role, UserInfo} from "../../domain/types";
import {LabeledItem} from "../../base/data/LabeledItem";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {AddressDisplay} from "../../base/displays/AddressDisplay";
import {MultiLingualTextDisplay} from "../../base/displays/MultiLingualTextDisplay";
import {RoleDisplay} from "../../base/displays/RoleDisplay";

interface UserProfileProps {
}

export function UserProfile(props: UserProfileProps) {
    const {t} = useLanguageContext();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();

    const [profile, setProfile] = useState<UserInfo>();
    const [tenant, setTenant] = useState<ITenant>();
    const [role, setRole] = useState<Role>();

    useEffect(() => {
        const currentUserProvider = new CurrentUserProvider(api);
        currentUserProvider.profile().then(setProfile);
    }, [api]);

    useEffect(() => {
        if (api && api.auth) {
            const currentUserProvider = new CurrentUserProvider(api);
            currentUserProvider.tenant(tenantUuid).then(setTenant);
            currentUserProvider.role(tenantUuid).then(setRole);
        }
    }, [api, tenantUuid]);

    return (
        <Container maxWidth="lg">
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs title={t("userProfile.title")} crumbs={[{
                        label: t("userProfile.title"),
                        href: "/current-user/profile"
                    }]} />
                </Grid>
                {profile &&
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardHeader title={`${profile.givenName} ${profile.name}`} />
                            <CardContent>
                                <Grid container spacing={gs}>
                                    <LabeledItem label={t("userProfile.givenName")}>{profile.givenName}</LabeledItem>
                                    <LabeledItem label={t("userProfile.name")}>{profile.name}</LabeledItem>
                                    <LabeledItem label={t("userProfile.email")}>{profile.email}</LabeledItem>
                                    {role &&
                                        <LabeledItem label={t("userProfile.role")}>
                                            <RoleDisplay role={role} />
                                        </LabeledItem>
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                }
                {tenant &&
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <CardHeader title={tenant.name} />
                            <CardContent>
                                <Grid container spacing={gs}>
                                    <LabeledItem label={t("tenant.singular")}>{tenant.name}</LabeledItem>
                                    {tenant.address &&
                                        <LabeledItem label={t("tenant.address")}>
                                            <AddressDisplay address={tenant.address} />
                                        </LabeledItem>
                                    }
                                    <LabeledItem label={t("tenant.industry")}>
                                        <MultiLingualTextDisplay texts={tenant.industry.names} />
                                    </LabeledItem>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button>{t("tenant.edit.title")}</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                }
            </Grid>
        </Container>
    );
}
