import {DateTime} from "luxon";
import React, {MouseEvent, useCallback, useMemo} from "react";
import {useLayoutContext} from "../../context/layout/LayoutContext";
import {DateDisplayFormat, luxonDate, stopMouseEvent} from "../../libs/tools";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface DateDisplayProps {
    date?: DateTime | Date | string;
    format?: DateDisplayFormat;
    emptyPlaceholder?: string;
}

export function DateDisplay(props: DateDisplayProps) {
    const {date, format, emptyPlaceholder} = props;
    const {language} = useLanguageContext();

    const {dateDisplayFormat, setDateDisplayFormat} = useLayoutContext();

    const formattedDate = useMemo<string>(() => {
        if (date) {
            const parsedDate = luxonDate(date);
            switch (dateDisplayFormat) {
                case DateDisplayFormat.Age:
                    return parsedDate.toRelative({locale: language || "en"}) || "";
                case DateDisplayFormat.Timestamp:
                    return parsedDate.toLocaleString({...DateTime.DATETIME_SHORT_WITH_SECONDS});
                case DateDisplayFormat.Date:
                    return parsedDate.toLocaleString({...DateTime.DATE_SHORT});
                case DateDisplayFormat.Time:
                    return parsedDate.toLocaleString({...DateTime.TIME_24_WITH_SECONDS});
                default:
                    throw new Error(`Unknown DateDisplayFormat enum constant ${dateDisplayFormat}`);
            }
        }
        return emptyPlaceholder || "";
    }, [date, dateDisplayFormat, emptyPlaceholder, language]);

    const handleFormatClicked = useCallback(
        (event: MouseEvent<HTMLSpanElement>) => {
            stopMouseEvent(event);
            console.log(dateDisplayFormat, "dateDisplayFormat");

            switch (dateDisplayFormat) {
                case DateDisplayFormat.Age:
                    setDateDisplayFormat(format || DateDisplayFormat.Timestamp);
                    break;
                case DateDisplayFormat.Timestamp:
                case DateDisplayFormat.Date:
                case DateDisplayFormat.Time:
                    setDateDisplayFormat(DateDisplayFormat.Age);
                    break;
                default:
                    throw new Error(`Unknown DateDisplayFormat enum constant ${dateDisplayFormat}`);
            }
        },
        [format, dateDisplayFormat]
    );

    return (
        <span onClick={(e) => handleFormatClicked(e)} style={{cursor: "pointer", whiteSpace: "nowrap"}}>
            {formattedDate}
        </span>
    );
}
