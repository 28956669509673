import React, {useMemo} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {InvoiceState} from "../../domain/types";
import {amber, green} from "@mui/material/colors";
import {Chip} from "@mui/material";
import {InvoiceStateAvatar} from "./InvoiceStateAvatar";
import {InvoiceStateIcon} from "./InvoiceStateIcon";

interface InvoiceStateChipProps {
    invoiceState: InvoiceState;
}

export function InvoiceStateChip(props: InvoiceStateChipProps) {
    const {invoiceState} = props;
    const {es} = useLanguageContext();

    const invoiceStateColor = useMemo(() => {
        switch (invoiceState) {
            case InvoiceState.Sent:
                return amber[700];
            case InvoiceState.PaymentProcessing:
                return amber[300];
            case InvoiceState.Payed:
                return green[300];
            default:
                return green[300];
        }
    }, [invoiceState]);

    return (
        <Chip
            icon={InvoiceStateIcon(invoiceState)}
            label={es("invoices.invoiceStates", invoiceState)}
            sx={{backgroundColor: invoiceStateColor}}
        />
    );
}