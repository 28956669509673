import React, {useEffect, useState} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {AContainer} from "../../base/layout/AContainer";
import {Grid} from "@mui/material";
import {gs} from "../../theme";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {useNavigate} from "react-router-dom";
import {InvoicesProvider} from "../../domain/InvoicesProvider";
import {defaultPage, IInvoice, Page, PageChange} from "../../domain/types";
import {APagingTable} from "../../base/table/APagingTable";
import {GridColDef} from "@mui/x-data-grid";
import {InvoiceStateChip} from "../../base/displays/InvoiceStateChip";

interface InvoicesListProps {

}

export function InvoicesList(props: InvoicesListProps) {
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();

    const [pageChange, setPageChange] = useState<PageChange>(defaultPage);
    const [invoices, setInvoices] = useState<Page<IInvoice>>();

    useEffect(() => {
        const invoicesProvider = new InvoicesProvider(api);
        if (api.auth) {
            invoicesProvider.list(tenantUuid, pageChange).then(setInvoices);
        }
    }, []);


    const {t} = useLanguageContext();
    const columns : GridColDef[] = [
        {field: "invoiceNumber", headerName: t("invoices.invoiceNumber"), flex: 1},
        {field: "state", headerName: t("invoices.state"), flex: 1,
            renderCell: (r) => <InvoiceStateChip invoiceState={r.row.state} />},
        {field: "invoiceDate", headerName: t("invoices.invoiceDate"), flex: 1}
    ];
    return (
        <AContainer fullWidth>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs title={t("invoices.plural")} crumbs={[
                        {label: t("invoices.plural"), href: `/tenants/${tenantUuid}/invoices`}
                    ]} />
                </Grid>
                {invoices && (
                    <Grid item xs={12}>
                        <APagingTable
                            page={invoices}
                            columns={columns}
                            onPageChanged={setPageChange}
                            onClick={(invoice: IInvoice) => navigate(`/tenants/${tenantUuid}/invoices/${invoice.uuid}`)}
                        />
                    </Grid>
                )}
            </Grid>
        </AContainer>
    );
}