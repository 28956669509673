import React from "react";
import {useLanguageContext} from "../../../context/language/LanguageContext";
import {IInvoice} from "../../../domain/types";
import {gs} from "../../../theme";
import {Grid} from "@mui/material";
import {LabeledItem} from "../../../base/data/LabeledItem";
import {DateDisplay} from "../../../base/displays/DateDisplay";
import {DateDisplayFormat} from "../../../libs/tools";
import {InvoiceStateChip} from "../../../base/displays/InvoiceStateChip";

interface InvoiceDetailsProps {
    invoice: IInvoice;
}

export function InvoiceDetails(props: InvoiceDetailsProps) {
    const {invoice} = props;
    const {t} = useLanguageContext();
    return (
        <Grid container spacing={gs}>
            <LabeledItem label={t("invoices.invoiceNumber")}>
                {invoice.invoiceNumber}
            </LabeledItem>
            <LabeledItem label={t("invoices.state")}>
                <InvoiceStateChip invoiceState={invoice.state} />
            </LabeledItem>
            <LabeledItem label={t("invoices.invoiceDate")}>
                <DateDisplay date={invoice.invoiceDate} format={DateDisplayFormat.Date} />
            </LabeledItem>
        </Grid>
    );
}