import * as React from "react";
import {PropsWithChildren} from "react";
import {Grid, TypographyVariant} from "@mui/material";
import {gs} from "../../theme";
import {AGridItemGrow} from "./AGridItemGrow";
import Typography from "@mui/material/Typography";

interface AHeaderProps extends PropsWithChildren<{
    title: string;
    variant: TypographyVariant;
}> {}

export function AHeader(props: AHeaderProps) {
    const {title, variant, children} = props;

    return (
        <Grid container spacing={gs}>
            <AGridItemGrow>
                <Typography variant={variant}>{title}</Typography>
            </AGridItemGrow>
            <Grid item>
                {children}
            </Grid>
        </Grid>
    );
}
