import React, {useEffect, useState} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {AContainer} from "../../base/layout/AContainer";
import {AppBar, Grid, Paper, Tab, Tabs, Toolbar} from "@mui/material";
import {gs} from "../../theme";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {ATabPanel} from "../../base/layout/ATabPanel";
import {useNavigate, useParams} from "react-router-dom";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {IInvoice} from "../../domain/types";
import {InvoicesProvider} from "../../domain/InvoicesProvider";
import Box from "@mui/material/Box";
import {InvoiceDetails} from "./view/InvoiceDetails";
import {InvoiceDocuments} from "./view/InvoiceDocuments";

interface InvoiceViewProps {

}

export function InvoiceView(props: InvoiceViewProps) {
    const routeParams = useParams();
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();

    const [invoice, setInvoice] = useState<IInvoice>();

    const [leftTabs, setLeftTabs] = useState<number>(0);
    const [rightTabs, setRightTabs] = useState<number>(0);

    useEffect(() => {
        if (routeParams.invoiceUuid && api.auth) {
            const invoicesProvider = new InvoicesProvider(api);
            invoicesProvider.get(tenantUuid, routeParams.invoiceUuid).then(setInvoice);
            // TODO move this fetch to the dedicated activities component
            invoicesProvider.listActivities(tenantUuid, routeParams.invoiceUuid).then(console.log);
        }
    }, [api, tenantUuid, routeParams]);

    return (
        <AContainer fullWidth>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs title={invoice ? `${t("invoices.singular")}: ${invoice.invoiceNumber}` : "..."} crumbs={[
                        {label: t("invoices.plural"), href: `/tenants/${tenantUuid}/invoices`},
                        {label: invoice ? `${t("invoices.singular")}: ${invoice.invoiceNumber}` : "...", href: `/tenants/${tenantUuid}/invoices/${routeParams.invoiceUuid}`}
                    ]} />
                </Grid>
                {invoice && (
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item lg={8} md={7} sm={6}>
                                <Paper>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <AppBar position="static" color="transparent" elevation={0}>
                                                <Tabs value={leftTabs}
                                                      onChange={(e, v) => setLeftTabs(v)}
                                                      indicatorColor="primary"
                                                      textColor="inherit">
                                                    <Tab label="Rechnungsdetails" />
                                                    <Tab label="Vorschau" />
                                                </Tabs>
                                            </AppBar>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ATabPanel value={leftTabs} index={0}>
                                                <Box p={gs}>
                                                    <InvoiceDetails invoice={invoice} />
                                                </Box>
                                            </ATabPanel>
                                            <ATabPanel value={leftTabs} index={1}>
                                                <Box p={gs}>
                                                    <InvoiceDocuments invoice={invoice} />
                                                </Box>
                                            </ATabPanel>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item lg={4} md={5} sm={6}>
                                <Paper>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <AppBar position="static" color="transparent" elevation={0}>
                                                <Tabs value={rightTabs}
                                                      onChange={(e, v) => setRightTabs(v)}
                                                      indicatorColor="primary"
                                                      textColor="inherit">
                                                    <Tab label="Chat" />
                                                    <Tab label="Aktivitäten" />
                                                </Tabs>
                                            </AppBar>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ATabPanel value={rightTabs} index={0}>
                                                Chat
                                            </ATabPanel>
                                            <ATabPanel value={rightTabs} index={1}>
                                                Aktivitäten
                                            </ATabPanel>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </AContainer>
    );
}