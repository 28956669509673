import React, {PropsWithChildren, useCallback, useState} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {Button, CircularProgress, Grid} from "@mui/material";
import {gs} from "../../theme";
import {AGridItemGrow} from "../layout/AGridItemGrow";
import {AConfirmDialog} from "../dialogs/AConfirmDialog";

interface AFormProps extends PropsWithChildren<{
    onSubmit: () => Promise<void>;
    onDelete?: () => Promise<void>;
    onCancel?: () => void;
    disabled?: boolean;
}> { }

export function AForm(props: AFormProps) {
    const {onSubmit, onCancel, onDelete, disabled, children} = props;
    const {t} = useLanguageContext();

    const [dirty, setDirty] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [confirmCancel, setConfirmCancel] = useState<boolean>(false);

    const handleSubmit = useCallback(() => {
        setSubmitting(true);
        onSubmit()
            .then(() => setDirty(false))
            .finally(() => setSubmitting(false));
    }, [onSubmit]);

    const handleDelete = useCallback(() => {
        if (onDelete) {
            setSubmitting(true);
            onDelete().finally(() => {
                setSubmitting(false);
            });
        }
    }, [onDelete]);

    const handleCancel = useCallback(() => {
        if (onCancel) {
            if (dirty && !confirmCancel) {
                setConfirmCancel(true);
            } else {
                onCancel();
                setConfirmCancel(false);
                setSubmitting(false);
                setDirty(false);
            }
        }
    }, [onCancel, confirmCancel]);

    return (
        <form onChange={() => setDirty(true)}>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    {children}
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gs}>
                        <AGridItemGrow>
                            {onDelete && (
                                <Button variant="outlined" color="secondary" onClick={handleDelete} disabled={disabled || submitting}>
                                    {t("delete")}
                                </Button>
                            )}
                        </AGridItemGrow>
                        {submitting && (
                            <Grid item>
                                <div style={{paddingTop: "0.3em"}}>
                                    <CircularProgress size="1.5em" />
                                </div>
                            </Grid>
                        )}
                        {onCancel && (
                            <Grid item>
                                <Button variant="outlined" onClick={handleCancel} disabled={submitting}>
                                    {t("cancel")}
                                </Button>
                            </Grid>
                        )}
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!dirty || disabled || submitting}>
                                {t("save")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <AConfirmDialog open={confirmCancel}
                            title={t("forms.dirty.title")}
                            message={t("forms.dirty.message")}
                            onConfirm={() => handleCancel()}
                            onCancel={() => setConfirmCancel(false)} />
        </form>
    );
}