import {Api} from "../libs/Api";
import {SearchRequest, SearchResponse} from "./types";

export class SearchProvider {
    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async search(tenantUuid: string, searchRequest: SearchRequest): Promise<SearchResponse> {
        const url = `/api/v1/tenants/${tenantUuid}/search`;
        return this.api.post<SearchResponse>(url, searchRequest);
    }

}