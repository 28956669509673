import * as React from "react";
import {Stack, Typography} from "@mui/material";

interface NoRowsOverlayProps {
    message: string;
}

export function NoRowsOverlay(props: NoRowsOverlayProps) {
    const {message} = props;

    return (
        <Stack height="100%" minHeight="60px" alignItems="center" justifyContent="center">
            <Typography variant="body1">{message}</Typography>
        </Stack>
    );
}
