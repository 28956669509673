import * as React from "react";
import {gs} from "../../theme";
import {Card, CardContent, CardHeader, Divider, Grid, Paper, Typography} from "@mui/material";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useAuthContext} from "../../context/auth/AuthContext";
import {DashboardProvider} from "../../domain/DashboardProvider";
import {DashboardStats} from "../../domain/dashboard";
import {AContainer} from "../../base/layout/AContainer";
import {InvoicesDashboardCard} from "./InvoicesDashboardCard";
import {KpiDisplay} from "../../base/displays/KpiDisplay";

interface DashboardProps {}

export function Dashboard(props: DashboardProps) {
    const {api} = useAuthContext();
    const {tenantUuid, tenant} = useTenantContext();
    const navigate = useNavigate();

    const [stats, setStats] = useState<DashboardStats>();

    useEffect(() => {
        if (api && api.auth) {
            const dashboardProvider = new DashboardProvider(api);
            dashboardProvider.stats(tenantUuid).then(setStats);
        }
    }, [api, tenantUuid]);

    const {t} = useLanguageContext();
    return (
        <AContainer fullWidth>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={tenant ? tenant.name : "..."}
                        crumbs={[{label: t("dashboard.singular"), href: `/tenants/${tenantUuid}`}]}
                    />
                </Grid>
                {stats && (
                    <Grid item xs={12}>
                        <Grid container spacing={gs}>
                            <Grid item xs={12} sm={6} md={4}>
                                <InvoicesDashboardCard invoices={stats.recentInvoices} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Card>
                                    <CardHeader title={t("dashboard.kpis")} />
                                    <CardContent>
                                        <Grid container spacing={gs}>
                                            <Grid item>
                                                <KpiDisplay title={t("invoices.plural")}>
                                                    {stats.invoices}
                                                </KpiDisplay>
                                            </Grid>
                                            <Grid item>
                                                <KpiDisplay title={t("tasks.plural")}>
                                                    {stats.tasks}
                                                </KpiDisplay>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </AContainer>
    );
}
