import {UserInfo} from "./types";

export class UserInfoWrapper {

    readonly userInfo: UserInfo;

    constructor(userInfo: UserInfo) {
        this.userInfo = userInfo
    }

    get displayName(): string {
        return [this.userInfo.givenName, this.userInfo.name]
            .filter((n) => n)
            .join(" ");
    }

}