import {ThemeProvider} from "@emotion/react";
import Box from "@mui/material/Box";
import * as React from "react";
import {BrowserRouter} from "react-router-dom";
import {AppRoutes} from "./AppRoutes";
import {AuthContextProvider} from "./context/auth/AuthContext";
import {LanguageContextProvider} from "./context/language/LanguageContext";
import {LayoutContextProvider} from "./context/layout/LayoutContext";
import {DrawerNav} from "./nav/DrawerNav";
import theme from "./theme";
import {TopNav} from "./nav/TopNav";
import {BottomNav} from "./nav/BottomNav";

export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <LanguageContextProvider>
                    <LayoutContextProvider>
                        <AuthContextProvider notAuthenticatedUrl="/auth/login">
                            <TopNav />
                            <DrawerNav />
                            <div style={{paddingTop: theme.spacing(2), minHeight: "calc(100vh - 126px)"}}>
                                <AppRoutes />
                            </div>
                            <BottomNav />
                        </AuthContextProvider>
                    </LayoutContextProvider>
                </LanguageContextProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}
