import React, {useEffect, useState} from "react";
import {Route} from "react-router";
import {Routes, useParams} from "react-router-dom";
import {TenantContextProvider} from "./context/tenant/TenantContext";
import {Index} from "./pages/Index";
import {AuthLogout} from "./pages/auth/AuthLogout";
import {AuthProcessToken} from "./pages/auth/AuthProcessToken";
import {TenantSelect} from "./pages/current-user/TenantSelect";
import {UserProfile} from "./pages/current-user/UserProfile";
import {Dashboard} from "./pages/dashboard/Dashboard";
import {RoleView} from "./pages/roles/RoleView";
import {RolesList} from "./pages/roles/RolesList";
import {UserView} from "./pages/users/UserView";
import {UsersList} from "./pages/users/UsersList";
import {InvoiceView} from "./pages/invoices/InvoiceView";
import {AuthLogin} from "./pages/auth/AuthLogin";
import {InvoicesList} from "./pages/invoices/InvoicesList";
import {BillingOrderView} from "./pages/billing-orders/BillingOrderView";
import {BillingOrdersList} from "./pages/billing-orders/BillingOrdersList";

interface RoutesProps {}

export function AppRoutes(props: RoutesProps) {
return (
        <Routes>
            <Route path="/current-user/*" element={<CurrentUserRoutes />} />
            <Route path="/tenants/:tenantUuid/*" element={<TenantRoutes />} />
            <Route path="/auth/*" element={<AuthRoutes />} />
            <Route path="/" element={<Index />} />
            <Route path="*" element={<div>404</div>} />
        </Routes>
    );
}

export function CurrentUserRoutes(props: RoutesProps) {
    return (
        <Routes>
            <Route path="/tenant-select" element={<TenantSelect />} />
            <Route path="/profile" element={<UserProfile />} />
        </Routes>
    );
}

export function TenantRoutes(props: RoutesProps) {
    const routeParams = useParams();
    const [tenantUuid, setTenantUuid] = useState<string>();

    useEffect(() => {
        if (routeParams.tenantUuid) {
            setTenantUuid(routeParams.tenantUuid);
        }
    }, []);

    if (!tenantUuid) {
        return null;
    }

    return (
        <TenantContextProvider tenantUuid={tenantUuid}>
            <Routes>
                <Route path="/invoices/:invoiceUuid" element={<InvoiceView />} />
                <Route path="/invoices" element={<InvoicesList />} />

                <Route path="/billing-orders/:billingOrderUuid" element={<BillingOrderView />} />
                <Route path="/billing-orders" element={<BillingOrdersList />} />

                <Route path="/users/:username" element={<UserView />} />
                <Route path="/users" element={<UsersList />} />

                <Route path="/roles/:roleUuid" element={<RoleView />} />
                <Route path="/roles" element={<RolesList />} />

                <Route path="/" element={<Dashboard />} />
            </Routes>
        </TenantContextProvider>
    );
}

export function AuthRoutes(props: RoutesProps) {
    return (
        <Routes>
            <Route path="/login" element={<AuthLogin />} />
            <Route path="/process-token" element={<AuthProcessToken />} />
            <Route path="/logout" element={<AuthLogout />} />
        </Routes>
    );
}
