import * as React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useAuthContext} from "../../context/auth/AuthContext";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {defaultPage, Page, PageChange, TenantUserInfo} from "../../domain/types";
import {UsersProvider} from "../../domain/UsersProvider";
import {Container, Grid} from "@mui/material";
import {gs} from "../../theme";
import {AHeaderWithBreadcrumbs} from "../../base/layout/AHeaderWithBreadcrumbs";
import {APagingTable} from "../../base/table/APagingTable";
import {GridColDef} from "@mui/x-data-grid";
import {AContainer} from "../../base/layout/AContainer";

interface UsersListProps {
}

export function UsersList(props: UsersListProps) {
    const {api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();

    const [pageChange, setPageChange] = useState<PageChange>(defaultPage);
    const [tenantUsers, setTenantUsers] = useState<Page<TenantUserInfo>>();
    const [selected, setSelected] = useState<string[]>([]);

    useEffect(() => {
        if (api.auth && api.auth.isLoggedIn) {
            const usersProvider = new UsersProvider(api);
            usersProvider.listTenantUsers(tenantUuid, pageChange).then(setTenantUsers);
        }
    }, [api, tenantUuid]);

    const {t} = useLanguageContext();
    const columns: GridColDef[] = [
        {field: "user.givenName", headerName: t("users.givenName"), flex: 1, valueGetter: (params) => params.row.user.givenName},
        {field: "user.name", headerName: t("users.name"), flex: 1, valueGetter: (params) => params.row.user.name},
        {field: "user.email", headerName: t("users.email"), flex: 1, valueGetter: (params) => params.row.user.email},
        {field: "tenantUser.role.name", headerName: t("roles.singular"), flex: 1, valueGetter: (params) => params.row.tenantUser.role.name},
    ];

    return (
        <AContainer>
            <Grid container spacing={gs}>
                <Grid item xs={12}>
                    <AHeaderWithBreadcrumbs
                        title={t("users.plural")}
                        crumbs={[{label: t("users.plural"), href: `/tenants/${tenantUuid}/users`}]} />
                </Grid>
                {tenantUsers && (
                    <Grid item xs={12}>
                        <APagingTable
                            page={tenantUsers}
                            columns={columns}
                            onPageChanged={setPageChange}
                            onClick={(user: TenantUserInfo) => navigate(`/tenants/${tenantUuid}/users/${user.user.sub}`)}
                            onSelectionChanged={setSelected}
                            getRowId={(row) => row.user.sub}
                        />
                    </Grid>
                )}
            </Grid>
        </AContainer>
    );
}
