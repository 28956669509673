import {Container, Grid, Link, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {AGridItemGrow} from "../base/layout/AGridItemGrow";
import theme, {gs} from "../theme";

interface BottomNavProps {}

export function BottomNav(props: BottomNavProps) {
    return (
        <Box
            p={2}
            sx={{
                position: "relative",
                width: "100%",
                bottom: 0,
                color: theme.palette.secondary.contrastText,
                backgroundColor: theme.palette.secondary.main
            }}
        >
            <Container maxWidth="lg" sx={{background: "transparent"}}>
                <Grid container spacing={gs}>
                    <Grid item>
                        <img alt="Company logo" src="/theme/oebb/oebb-logo-white.png" />
                    </Grid>
                    <AGridItemGrow>
                        <Typography variant="body2">© 2025 - fobi solutions GmbH im Auftrag der ÖBB-Business Competence Center GmbH</Typography>
                    </AGridItemGrow>
                    <Grid item>
                        <Typography variant="body2">
                            <Link href="https://bcc.oebb.at/de/impressum" color={theme.palette.secondary.contrastText}>
                                Impressum
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
