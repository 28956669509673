import {Api} from "../libs/Api";
import {DashboardStats} from "./dashboard";

export class DashboardProvider {

    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async stats(tenantUuid: string): Promise<DashboardStats> {
        const url = `/api/v1/tenants/${tenantUuid}/dashboard/stats`;
        return this.api.get<DashboardStats>(url);
    }

}