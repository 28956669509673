import React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {IInvoice} from "../../domain/types";
import {
    Button,
    Card,
    CardActions,
    CardHeader,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText
} from "@mui/material";
import {DateDisplay} from "../../base/displays/DateDisplay";
import {DateDisplayFormat} from "../../libs/tools";
import {useTenantContext} from "../../context/tenant/TenantContext";
import {useNavigate} from "react-router-dom";
import {InvoiceStateAvatar} from "../../base/displays/InvoiceStateAvatar";

interface InvoicesDashboardListProps {
    invoices: IInvoice[];
}

export function InvoicesDashboardCard(props: InvoicesDashboardListProps) {
    const {invoices} = props;
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();
    return (
        <Card>
            <CardHeader title={t("invoices.plural")} />
            <List>
                {invoices.map((invoice) => (
                    <ListItemButton key={invoice.uuid} onClick={() => navigate(`/tenants/${tenantUuid}/invoices/${invoice.uuid}`)}>
                        <ListItemAvatar>
                            <InvoiceStateAvatar invoiceState={invoice.state} />
                        </ListItemAvatar>
                        <ListItemText primary={invoice.invoiceNumber} secondary={<DateDisplay date={invoice.invoiceDate} format={DateDisplayFormat.Date} />} />
                    </ListItemButton>
                ))}
            </List>
            <CardActions>
                <Button onClick={() => navigate(`/tenants/${tenantUuid}/invoices`)}>{t("invoices.showAllInvoices")}</Button>
            </CardActions>
        </Card>
    );
}
