import React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface BillingOrderViewProps {

}

export function BillingOrderView(props: BillingOrderViewProps) {
    const {t} = useLanguageContext();
    return (
        <div>BillingOrderView</div>
    );
}