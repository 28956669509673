import React from "react";
import {useLanguageContext} from "../../context/language/LanguageContext";

interface BillingOrdersListProps {

}

export function BillingOrdersList(props: BillingOrdersListProps) {
    const {t} = useLanguageContext();
    return (
        <div>BillingOrdersList</div>
    );
}