import {Api} from "../libs/Api";
import {IActivity, IInvoice, IInvoiceDocument, Page, PageChange} from "./types";

export class InvoicesProvider {

    readonly api: Api;

    constructor(api: Api) {
        this.api = api;
    }

    async list(tenantUuid: string, pageChange: PageChange): Promise<Page<IInvoice>> {
        const url = this.api.appendPagingParams(`/api/v1/tenants/${tenantUuid}/invoices`, pageChange);
        return this.api.get<Page<IInvoice>>(url);
    }

    async get(tenantUuid: string, invoiceUuid: string): Promise<IInvoice> {
        const url = `/api/v1/tenants/${tenantUuid}/invoices/${invoiceUuid}`;
        return this.api.get<IInvoice>(url);
    }

    async listDocuments(tenantUuid: string, invoiceUuid: string): Promise<IInvoiceDocument[]> {
        const url = `/api/v1/tenants/${tenantUuid}/invoices/${invoiceUuid}/documents`;
        return this.api.get<IInvoiceDocument[]>(url);
    }

    async listActivities(tenantUuid: string, invoiceUuid: string): Promise<IActivity[]> {
        const url = `/api/v1/tenants/${tenantUuid}/invoices/${invoiceUuid}/activities`;
        return this.api.get<IActivity[]>(url);
    }
}