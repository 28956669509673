import * as React from "react";
import {PropsWithChildren} from "react";
import {Typography} from "@mui/material";

interface KpiDisplayProps extends PropsWithChildren<{
    title: string;
}> {}

export function KpiDisplay(props: KpiDisplayProps) {
    const {title, children} = props;

    return (
        <div>
            <Typography variant="overline">{title}</Typography>
            <Typography variant="body1">{children}</Typography>
        </div>
    );
}
