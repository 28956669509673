import * as React from "react";
import {Breadcrumbs} from "@mui/material";
import {ALink} from "./ALink";

export interface ACrumb {
    label: string;
    href: string;
}

interface ABreadcrumbsProps {
    crumbs: ACrumb[];
}

export function ABreadcrumbs(props: ABreadcrumbsProps) {
    const {crumbs} = props;

    if(crumbs.length === 0) {
        return null;
    }

    return (
        <Breadcrumbs>
            {crumbs.map((crumb, index) =>
                <ALink key={index} href={crumb.href}>{crumb.label}</ALink>
            )}
        </Breadcrumbs>
    );
}
