export interface AuthConfiguration {
    hostedUiDomain: string;
    clientId: string;
}

export interface AuthCodeResponse {
    idToken: string;
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    tokenType: string;
}

interface AuthenticatedTokenStored {
    idToken: string;
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    tokenType: string;
    createdAt: Date;
    expiresAt: Date;
}

export class AuthenticatedToken {

    private token: AuthenticatedTokenStored;

    constructor(token: AuthCodeResponse) {
        const now = new Date();
        this.token = {
            idToken: token.idToken,
            accessToken: token.accessToken,
            refreshToken: token.refreshToken,
            expiresIn: token.expiresIn,
            tokenType: token.tokenType,
            createdAt: now,
            expiresAt: new Date(now.getTime() + (token.expiresIn * 1000))
        };
    }

    get isLoggedIn() {
        return Boolean(this.token.accessToken && this.token.expiresAt > new Date());
    }

    get authHeader() {
        return `${this.token.tokenType} ${this.token.accessToken}`;
    }

    static nonAuthenticated(): AuthenticatedToken {
        return new AuthenticatedToken({
            idToken: "",
            accessToken: "",
            refreshToken: "",
            expiresIn: 0,
            tokenType: ""
        });
    }

    toSessionStorage() {
        sessionStorage.setItem("authenticatedToken", JSON.stringify(this.token));
    }

    static fromSessionStorage(): AuthenticatedToken | undefined {
        const token = sessionStorage.getItem("authenticatedToken");
        if (token) {
            const constructed = this.nonAuthenticated();
            const readData = JSON.parse(token);
            constructed.token = {
                ... readData,
                createdAt: Date.parse(readData.createdAt),
                expiresAt: Date.parse(readData.expiresAt)
            };
            return constructed;
        }
    }

    clearSessionStorage() {
        sessionStorage.removeItem("authenticatedToken");
    }
}