import * as React from "react";
import {MultilingualText, MultilingualTextWrapper} from "../../domain/types";
import {useLanguageContext} from "../../context/language/LanguageContext";
import {useMemo} from "react";

interface MultiLingualTextDisplayProps {
    texts: MultilingualText[];
}

export function MultiLingualTextDisplay(props: MultiLingualTextDisplayProps) {
    const {texts} = props;
    const {language} = useLanguageContext();

    const resolvedText = useMemo<string>(() => new MultilingualTextWrapper(texts).resolved(language), [texts, language]);

    return (
        <span>{resolvedText}</span>
    );
}
