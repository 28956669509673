import React, {PropsWithChildren} from "react";
import {Container} from "@mui/material";
import theme from "../../theme";
import Box from "@mui/material/Box";

interface AContainerProps extends PropsWithChildren<{
    fullWidth?: boolean;
}> {}

export function AContainer(props: AContainerProps) {
    const {fullWidth, children} = props;

    return (
        <Container maxWidth={fullWidth ? false : "lg"} style={{paddingBottom: theme.spacing(2)}}>
            <Box paddingBottom={2}>
                {children}
            </Box>
        </Container>
    );
}