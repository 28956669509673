import MenuIcon from "@mui/icons-material/Menu";
import {AppBar, IconButton, Toolbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {useAuthContext} from "../context/auth/AuthContext";
import {useLayoutContext} from "../context/layout/LayoutContext";
import {useTenantContext} from "../context/tenant/TenantContext";
import {GlobalSearchInput} from "./GlobalSearchInput";
import {LanguageSwitcherMenu} from "./LanguageSwitcherMenu";
import {UserMenu} from "./UserMenu";

interface TopNavProps {}

export function TopNav(props: TopNavProps) {
    const {title, drawerNavOpen, setDrawerNavOpen} = useLayoutContext();
    const {auth, api} = useAuthContext();
    const {tenantUuid} = useTenantContext();
    const navigate = useNavigate();

    const toggleNavDrawer = useCallback(() => {
        setDrawerNavOpen(!drawerNavOpen);
    }, [drawerNavOpen, setDrawerNavOpen]);

    return (
        <Box
            sx={{
                flexGrow: 1,
                position: "relative",
                zIndex: (theme) => theme.zIndex.drawer + 1,
                background: "#FFF"
            }}
        >
            <AppBar position="static" color="transparent">
                <Toolbar>
                    {auth.isLoggedIn && (
                        <IconButton size="large" edge="start" color="inherit" sx={{mr: 2}} onClick={toggleNavDrawer}>
                            <MenuIcon />
                        </IconButton>
                    )}
                    <img
                        onClick={() => navigate(`/tenants/${tenantUuid}`)}
                        src="/theme/oebb/oebb-logo.png"
                        alt="OEBB Rechnungen"
                        style={{maxHeight: "50px", cursor: "pointer"}}
                    />
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{flexGrow: 1, paddingLeft: "2em", display: {xs: "none", sm: "block"}}}
                    >
                        {title}
                    </Typography>
                    {auth.isLoggedIn && (
                        <>
                            <GlobalSearchInput />
                            <Box p={2} />
                            <LanguageSwitcherMenu />
                            <UserMenu />
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
