import {Button, Card, CardActions, CardContent, CardHeader, Container, Grid, Hidden, Typography} from "@mui/material";
import * as React from "react";
import {useAuthContext} from "../context/auth/AuthContext";
import {gs} from "../theme";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLanguageContext} from "../context/language/LanguageContext";
import {AHeaderWithBreadcrumbs} from "../base/layout/AHeaderWithBreadcrumbs";
import HiddenCss from "@mui/material/Hidden/HiddenCss";
import {AContainer} from "../base/layout/AContainer";
import {AForm} from "../base/forms/AForm";
import {TextInput} from "../base/inputs/TextInput";

interface IndexProps {}

export function Index(props: IndexProps) {
    const {api} = useAuthContext();
    const navigate = useNavigate();
    const {t} = useLanguageContext();
    const [checking, setChecking] = useState<boolean>(true);

    const [value, setValue] = useState<string>("");

    useEffect(() => {
        if (api.auth && api.auth.isLoggedIn) {
            navigate("/current-user/tenant-select");
        }
        setChecking(false);
    }, [api]);

    const handleFakePromise = () => {
        return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, 2000);
        });
    };

    return (
        <AContainer>
            <Grid container spacing={gs}>
                {checking && (
                    <Grid item xs={12}>
                        <AHeaderWithBreadcrumbs title={t("checking")} crumbs={[]} />
                    </Grid>
                )}
                {!checking && (
                    <>
                        <Grid item xs={12}>
                            <AHeaderWithBreadcrumbs title={t("home.title")} crumbs={[]} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card>
                                <CardHeader title={t("home.card.title")} />
                                <CardContent>
                                    <Typography variant="body1">{t("home.card.info")}</Typography>
                                </CardContent>
                                <CardActions>
                                    <Button variant="outlined" color="primary" onClick={() => navigate("/auth/login")}>
                                        {t("login.singular")}
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={() => navigate("/auth/login")}>
                                        {t("home.card.signup")}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <AForm onSubmit={handleFakePromise} onDelete={handleFakePromise} onCancel={() => console.log("Cancel")}>
                                <Grid container spacing={gs}>
                                    <Grid item xs={4}>
                                        <TextInput label="Test" value={value} onChange={setValue} />
                                    </Grid>
                                </Grid>
                            </AForm>
                        </Grid>
                        <Hidden xsDown>
                            <Grid item xs={12} sm={6} md={8}>
                                <img
                                    alt="Visual for login"
                                    src="/undraw_japan_ubgk.svg"
                                    style={{width: "85%", marginLeft: "auto", marginRight: "auto", display: "block"}}
                                />
                            </Grid>
                        </Hidden>
                    </>
                )}
            </Grid>
        </AContainer>
    );
}
